@use 'variables';

@keyframes reveal {
	from {
		opacity: 0;
	}
}

@keyframes scale-a-lil {
	from {
		scale: .75;
	}
}
	
@media (prefers-reduced-motion: no-preference) {
	.portfolio-item {
		animation: scale-a-lil linear both;
		animation-timeline: view();
		animation-range: 25vh 75vh;
	}
}

.portfolio-item {
	display: flex;
	flex-direction: column;
	position: relative;
}

.portfolio-item a {
	width: 40em;
    height: 28em;
	overflow: hidden;
	border-radius: 12px;
}

.portfolio-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.portfolio-item-about {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.portfolio-item-title {
	font-size: 1em;
	font-weight: bold;
}

.portfolio-item-description {
	font-size: 1em;
}

.portfolio-item-created {
	font-style: italic;
}

a.portfolio-item-btn {
	color: black;
	background-color: #FFA4B6;
	text-transform: none;
	text-decoration: none;
	padding: 0.5em 1em;
	margin-top: 1em;
	border-radius: 4px;
}