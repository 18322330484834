@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

// Colors
$pink: #FFA4B6;
$bliss-pink: rgb(255, 0, 93);

// Breakpoints
$breakpoint-mobile: 320px;
$breakpoint-tablet: 768px;
$breakpoint-laptop: 1025px;

$font-family-title: 'Roboto';