@use 'variables';

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes appear {
	from {
		opacity: 0;
		clip-path: inset(100% 100% 0 0);
	}
	to {
		opacity: 1;
		clip-path: inset(0 0 0 0);
	}
}

.animate-on-scroll {
	animation: appear linear;
	animation-timeline: view();
	animation-range: entry 0% cover 40%;
}

.button-boxed {
	align-items: center;
	background-color: #fff;
	border: 2px solid #000;
	box-sizing: border-box;
	color: #000;
	cursor: pointer;
	display: inline-flex;
	fill: #000;
	font-family: Inter,sans-serif;
	font-size: 16px;
	font-weight: 600;
	height: 48px;
	justify-content: center;
	letter-spacing: -.8px;
	line-height: 24px;
	min-width: 140px;
	outline: 0;
	padding: 0 17px;
	text-align: center;
	text-decoration: none;
	transition: all .3s;
	user-select: none;
	-webkit-user-select: none;
	touch-action: manipulation;
}

.button-boxed:focus {
	color: #171e29;
}

.button-boxed:hover {
	border-color: variables.$bliss-pink;;
	fill: variables.$bliss-pink;;
	color: variables.$bliss-pink;;
}

.button-boxed:active {
	border-color: variables.$bliss-pink;;
	fill: variables.$bliss-pink;;
	color: variables.$bliss-pink;;
}

.button-boxed[data-selected="true"] {
	background-color: variables.$bliss-pink;;
	border-color: variables.$bliss-pink;;
	color: #fff;
	fill: variables.$bliss-pink;;
}

@media (min-width: 768px) {
	.button-boxed {
		min-width: 170px;
	}
}

.underlined {
	text-decoration: none;
	color: #18272F;
	font-weight: 700;
	position: relative;
}

.underlined::before {
	content: '';
	background-color: hsla(196, 61%, 58%, .75);
	position: absolute;
	left: 0;
	bottom: 3px;
	width: 100%;
	height: 8px;
	z-index: -1;
	transition: all .3s ease-in-out;
}

.underlined:hover::before {
	bottom: 0;
	height: 100%;
}
