@use 'variables';

.header-section {
	margin: 2em 5em;

	.app-header {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.menu-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		list-style: none;
		width: 100%;
		padding: 0;
		margin: 0 auto;
	}

	a.nav-item-link {
		color: black;
		text-decoration: none;
		text-transform: none;
		margin: 0.5em;
		white-space: nowrap;
		transition: color 0.3s ease;

		&:hover {
			color: lavender;
		}

		&.selected {
			font-weight: 600;
		}
	}
}
