@use 'variables';

@keyframes scale {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.1);
    }
}

.portfolio-section {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	margin: 2em 10em 6em 10em;

	.portfolio-section-categories {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 5em;
	}

	.portfolio-section-category {
		margin: .4em;
	}

	.portfolio-section-projects {
	    display: grid;
	    margin-top: 20px;
	    grid-template-columns: repeat(1,1fr);
	    grid-gap: 10px;
	}

}

@media (min-width: variables.$breakpoint-tablet) {
	.portfolio-section {
		.portfolio-section-projects {
		    display: grid;
		    margin-top: 20px;
		    grid-template-columns: repeat(1,1fr);
		    grid-gap: 10px;
		}
	}
}

@media (min-width: variables.$breakpoint-laptop) {
	.portfolio-section {
			.portfolio-section-projects {
		    display: grid;
		    margin-top: 20px;
		    grid-template-columns: repeat(2,1fr);
		    grid-gap: 4em;
		}
	}
}
