@use 'variables';

.app-footer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 2em 5em;

	.copyright {
		font-size: 0.7em;
	}

	.footer-socials {
		.footer-social {
			padding: 0.5em;
		}

		.footer-social:hover svg {
			fill: variables.$bliss-pink;
			color: variables.$bliss-pink;
		}

		.social-name {
			display: none;
		}

		svg {
			width: 15px;
			height: 15px;
		}
		
	}

}
